@import '~antd/dist/antd.css';

@font-face {
  font-family : 'webfontzk';
  font-display: swap;
  src         : url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.eot');
  /* IE9*/
  src         : url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.woff2') format('woff2'),
    url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.woff') format('woff'),
    /* chrome、firefox */
    url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url('//at.alicdn.com/t/webfont_3u4i2u4vd8t.svg#站酷高端黑') format('svg');
  /* iOS 4.1- */
}

@font-face {
  font-family : 'webfontzkxw';
  font-display: swap;
  src         : url('//at.alicdn.com/t/webfont_blv6u6u65b.eot');
  /* IE9*/
  src         : url('//at.alicdn.com/t/webfont_blv6u6u65b.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('//at.alicdn.com/t/webfont_blv6u6u65b.woff2') format('woff2'),
    url('//at.alicdn.com/t/webfont_blv6u6u65b.woff') format('woff'),
    /* chrome、firefox */
    url('//at.alicdn.com/t/webfont_blv6u6u65b.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url('//at.alicdn.com/t/webfont_blv6u6u65b.svg#站酷小薇体') format('svg');
  /* iOS 4.1- */
}

.App {
  text-align: center;
  color     : #fedcba;
}

table {
  width          : 100%;
  border-collapse: collapse;
  margin-top     : 15px;
  line-height    : 25px;
}

body {
  overflow-y: auto !important;
}

th {
  background-color: #eee;
}

td,
th {
  text-align: center;
}

td:first-child {
  text-align: left;
}

input {
  min-width    : 300px;
  border       : 1px solid #999;
  border-radius: 2px;
  line-height  : 25px;
}

#main {
  display        : flex;
  justify-content: flex-end;
  //font-family: 'xingyan';
  /* .slide {
    margin-left  : 30px;
  } */
  margin-bottom  : 150px;

  .placeholder {
    width: auto;
    flex : 1;
  }

  #merge {
    width: 100%;
  }

  .box {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
    margin-top     : 10px;

    .btns {
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
    }

    .btn {
      margin-left: 10px;
    }

    div {
      margin-left: 10px;
    }
  }

  /*  .main-container {
    background: #f8f8f8;
  } */
  .example {
    width         : 440px;
    min-height    : 300px;
    background    : #ffffff;
    //border-right: 1px solid #eee;
    margin-right  : 10px;

    .example-header {
      position     : relative;
      padding      : 16px 24px;
      color        : rgba(0, 0, 0, 0.65);
      //background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;

      .example-header-h3 {
        margin     : 0;
        color      : rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size  : 16px;
        line-height: 22px;
      }
    }

    .ul {
      display        : flex;
      justify-content: space-around;
      flex-wrap      : wrap;
    }

    .li {
      width     : 200px;
      height    : 306px;
      margin-top: 10px;
      cursor    : pointer;
      border    : 1px solid #f0f0f0;

      img {
        width : 100%;
        height: 100%;
      }
    }
  }
}

.option {
  margin-top: 10px;

  .option-li {
    margin-top : 10px;
    border     : 1px solid #eee;
    padding    : 0 20px 10px;
    margin-left: 10px;
  }

  .row {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-top     : 10px;

    .h3 {
      color       : #333;
      font-size   : 16px;
      width       : 110px;
      margin-right: 10px;
    }

    .ant-btn {
      margin-right: 10px;
    }

    .ant-input {
      width    : 200px;
      min-width: auto;
    }
  }
}

.option-drawer {

  /* .option-drawer-header {
    height: 33px;
  } */
  .option-li {
    border : 0;
    padding: 0;

    .row:first-child {
      margin-bottom: 30px;
    }
  }
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width           : 8px;
  height          : 8px;
  border-radius   : 10px;
  background-color: #F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius     : 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color  : #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius     : 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius     : 10px;
  /* 线性渐变 */
  background-image  : -webkit-gradient(linear,
      left bottom, right top,
      color-stop(0.44, rgb(60, 186, 146)),
      color-stop(0.72, rgb(253, 187, 45)),
      color-stop(0.86, rgb(253, 187, 45)));
  transition: 0.3s ease-in-out;
}

/*定义滑块悬浮样式*/
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-gradient(linear,
      left bottom, left top,
      color-stop(0.44, rgb(253, 187, 45)),
      olor-stop(0.72, rgb(253, 187, 45)),
      color-stop(0.86, rgb(60, 186, 146)));
  transition: 0.3s ease-in-out;
}

.canvas-container {
  border               : 1px solid #f1f1f1;
  border-radius        : 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius   : 8px;
  -ms-border-radius    : 8px;
  -o-border-radius     : 8px;
  overflow             : hidden;
}

.option-li {
  border-radius        : 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius   : 8px;
  -ms-border-radius    : 8px;
  -o-border-radius     : 8px;
  overflow             : hidden;
}

.ul {
  .li {
    border-radius        : 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius   : 8px;
    -ms-border-radius    : 8px;
    -o-border-radius     : 8px;
    overflow             : hidden;
  }
}
.row-op{
  float:right;
  width:300px;
  height: 50px;
}
.row-op:nth-of-type(2n+2){
  float:left;
}