@charset "UTF-8";
@import url(~antd/dist/antd.css);
@font-face {
  font-family: 'webfontzk';
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.woff2") format("woff2"), url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.woff") format("woff"), url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.ttf") format("truetype"), url("//at.alicdn.com/t/webfont_3u4i2u4vd8t.svg#站酷高端黑") format("svg");
  /* iOS 4.1- */ }

@font-face {
  font-family: 'webfontzkxw';
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_blv6u6u65b.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_blv6u6u65b.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/webfont_blv6u6u65b.woff2") format("woff2"), url("//at.alicdn.com/t/webfont_blv6u6u65b.woff") format("woff"), url("//at.alicdn.com/t/webfont_blv6u6u65b.ttf") format("truetype"), url("//at.alicdn.com/t/webfont_blv6u6u65b.svg#站酷小薇体") format("svg");
  /* iOS 4.1- */ }

.App {
  text-align: center;
  color: #fedcba; }

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  line-height: 25px; }

body {
  overflow-y: auto !important; }

th {
  background-color: #eee; }

td,
th {
  text-align: center; }

td:first-child {
  text-align: left; }

input {
  min-width: 300px;
  border: 1px solid #999;
  border-radius: 2px;
  line-height: 25px; }

#main {
  display: flex;
  justify-content: flex-end;
  /* .slide {
    margin-left  : 30px;
  } */
  margin-bottom: 150px;
  /*  .main-container {
    background: #f8f8f8;
  } */ }
  #main .placeholder {
    width: auto;
    flex: 1; }
  #main #merge {
    width: 100%; }
  #main .box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px; }
    #main .box .btns {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    #main .box .btn {
      margin-left: 10px; }
    #main .box div {
      margin-left: 10px; }
  #main .example {
    width: 440px;
    min-height: 300px;
    background: #ffffff;
    margin-right: 10px; }
    #main .example .example-header {
      position: relative;
      padding: 16px 24px;
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0; }
      #main .example .example-header .example-header-h3 {
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px; }
    #main .example .ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
    #main .example .li {
      width: 200px;
      height: 306px;
      margin-top: 10px;
      cursor: pointer;
      border: 1px solid #f0f0f0; }
      #main .example .li img {
        width: 100%;
        height: 100%; }

.option {
  margin-top: 10px; }
  .option .option-li {
    margin-top: 10px;
    border: 1px solid #eee;
    padding: 0 20px 10px;
    margin-left: 10px; }
  .option .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; }
    .option .row .h3 {
      color: #333;
      font-size: 16px;
      width: 110px;
      margin-right: 10px; }
    .option .row .ant-btn {
      margin-right: 10px; }
    .option .row .ant-input {
      width: 200px;
      min-width: auto; }

.option-drawer {
  /* .option-drawer-header {
    height: 33px;
  } */ }
  .option-drawer .option-li {
    border: 0;
    padding: 0; }
    .option-drawer .option-li .row:first-child {
      margin-bottom: 30px; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #F5F5F5; }

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* 线性渐变 */
  background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0.44, #3cba92), color-stop(0.72, #fdbb2d), color-stop(0.86, #fdbb2d));
  transition: 0.3s ease-in-out; }

/*定义滑块悬浮样式*/
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #fdbb2d), olor-stop(0.72, #fdbb2d), color-stop(0.86, #3cba92));
  transition: 0.3s ease-in-out; }

.canvas-container {
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden; }

.option-li {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden; }

.ul .li {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden; }

.row-op {
  float: right;
  width: 300px;
  height: 50px; }

.row-op:nth-of-type(2n+2) {
  float: left; }
